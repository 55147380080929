<template>
  <div class="test">
    <el-header class="header">
      <edit-header
        :title="title"
        :description="description"
        :isReleased="isReleased"
        v-on:titleChanged="changeTitle($event)"
        v-on:descriptionChanged="changeDescription($event)"
        v-on:industryChanged="changeIndustry($event)"
        v-on:companyChanged="changeCompany($event)"
        v-on:advancedSetting="openSetting($event)"
        v-on:publishClicked="publish($event)"
        v-on:saveClicked="save($event)"
        v-on:qnPreview="preview($event)"
        v-on:onConfirm="dialogCancel($event)"
        v-on:shareClicked="share($event)"
      >
      </edit-header>
    </el-header>

    <el-container class="container">
      <el-aside class="side">
        <el-tabs v-model="activeName" @tab-click="InitOutline">
          <el-tab-pane label="Type" name="first">
            <div class="edit">
              <!-- <div class="info">
                  <div class="edit-title">
                    个人信息
                  </div>
                  <div class="edit-ques">
                    <i class="el-icon-user"></i>
                    <span> 姓名&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                    <i class="el-icon-circle-plus type-icon" @click="willAddQuestion.type='name';qsEditDialogVisible=true"></i>
                  </div>
                  <div class="edit-ques">
                    <i class="el-icon-discount"></i>
                    <span> 学号&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                    <i class="el-icon-circle-plus type-icon" @click="willAddQuestion.type='stuId';qsEditDialogVisible=true"></i>
                  </div>
                  <div class="edit-ques">
                    <i class="el-icon-school"></i>
                    <span> 班级&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                    <i class="el-icon-circle-plus type-icon" @click="willAddQuestion.type='class';qsEditDialogVisible=true"></i>
                  </div>
                  <div class="edit-ques">
                    <i class="el-icon-date"></i>
                    <span> 学校&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                    <i class="el-icon-circle-plus type-icon" @click="willAddQuestion.type='school';qsEditDialogVisible=true"></i>
                  </div>
                </div> -->

              <div class="ques">
                <div class="edit-title">
                  Question Type
                </div>
                <div class="edit-ques">
                  <i class="el-icon-check"></i>
                  <span>
                    Single Choice&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'single_choice';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-finished"></i>
                  <span> Multiple Choice&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'multiple_choice';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    String&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'string';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    Float&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'float';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    Email&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'email';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    Date&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'date';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span> Multiple Object&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'multiple_object';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span>
                    Address&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span
                  >
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'address';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <div class="edit-ques">
                  <i class="el-icon-edit-outline"></i>
                  <span> Export Template&emsp;&emsp;&emsp;&emsp;&emsp;</span>
                  <i
                    class="el-icon-circle-plus type-icon"
                    @click="
                      willAddQuestion.type = 'template';
                      qsEditDialogVisible = true;
                    "
                  ></i>
                </div>
                <!--                <div class="edit-ques">-->
                <!--                  <i class="el-icon-circle-check"></i>-->
                <!--                  <span> 判断题&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;</span>-->
                <!--                  <i class="el-icon-circle-plus type-icon" @click="willAddQuestion.type='judge';qsEditDialogVisible=true"></i>-->
                <!--                </div>-->
              </div>
            </div>
          </el-tab-pane>

          <!-- <el-tab-pane label="Outline" name="second">
              <div class="outline">
                <el-tree
                    :data="outline"
                    node-key="id"
                    :props="defaultProps"
                    @node-drop="handleDrop"
                    draggable
                    :allow-drop="allowDrop"
                ></el-tree>
              </div>
            </el-tab-pane> -->
        </el-tabs>
      </el-aside>

      <el-main class="main">
        <div class="ques">
          <div class="title">Questionnaire Title: {{ title }}</div>
          <div class="industry">Industry: &emsp;&emsp;{{ industry }}</div>
          <div class="company">Company Type: &emsp;&emsp;{{ company }}</div>
          <div class="description">
            Description: &emsp;&emsp;{{ description }}
          </div>
          <div class="content">
            <el-row
              class="ques-block"
              v-for="item in questions"
              :key="item.num"
              @mouseover.native="hoverItem = item.id"
            >
              <el-col :span="16" class="block-content">
                <div class="block-title" v-if="item.type === 'judge'">
                  {{ item.id }}. 判断：{{ item.question_en }}
                  <span class="must" v-if="item.must">(Must)</span>
                </div>
                <div v-else class="block-title">
                  {{ item.id }}. {{ item.question_en }}
                  <span class="must" v-if="item.must">(Must)</span>
                </div>

                <div
                  class="block-description"
                  v-if="
                    item.description_en !== '' &&
                      item.description_en !== null &&
                      item.description_en !== undefined
                  "
                >
                  {{ item.description_en }}
                </div>
                <div class="block-description">
                  Question Name(tag): {{ item.name }}
                </div>
                <div class="block-description">
                  Question Type: {{ item.type }}
                </div>

                <!--                  图片-->
                <!-- <el-row class="block-img" v-for="(i,index) in item.imgList" :key="i.index">
                    <el-col :offset="2" :span="10" class="demo-image__preview" v-if="index%2===0">
                      <el-image
                          style="width: 200px; height: 200px"
                          :src="i.url"
                          :preview-src-list="[i.url]">
                      </el-image>
                    </el-col>
                    <el-col :span="10" class="demo-image__preview" v-if="index%2===0&&index+1<=item.imgList.length-1">
                      <el-image
                          style="width: 200px; height: 200px"
                          :src="item.imgList[index+1].url"
                          :preview-src-list="[item.imgList[index+1].url]">
                      </el-image>
                    </el-col>
                  </el-row>
                  <span style="color: #9b9ea0;font-size: x-small" v-if="item.imgList.length!==0">（点击图片查看大图）</span> -->

                <!--                视频-->
                <!-- <el-row class="block-img" v-for="i in item.videoList" :key="i.index">
                    <embed width=400 height=230 transparentatstart=true
                           animationatstart=false autostart=true autosize=false volume=100
                           displaysize=0 showdisplay=true showstatusbar=true showcontrols=true
                           showaudiocontrols=true showtracker=true showpositioncontrols=true
                           balance=true :src="i.url">
                  </el-row> -->

                <div
                  class="block-choice"
                  v-for="ans in item.options"
                  :key="ans.id"
                >
                  <!--                  单选-->
                  <el-radio v-if="item.type === 'single_choice'" value="0">
                    {{ ans.title }}
                  </el-radio>

                  <!--                  多选-->
                  <el-checkbox v-if="item.type === 'multiple_choice'" value="0">
                    {{ ans.title }}
                  </el-checkbox>

                  <!--                  填空-->
                  <el-input
                    v-if="
                      item.type === 'string' ||
                        item.type === 'float' ||
                        item.type === 'email' ||
                        item.type === 'address' ||
                        item.type === 'date'
                    "
                    type="textarea"
                    placeholder=""
                    v-bind="ans.title"
                  >
                  </el-input>

                  <!--                  判断-->
                  <el-radio v-if="item.type === 'judge'" value="0">
                    {{ ans.title }}
                  </el-radio>

                  <!--               个人信息-->
                  <el-input
                    v-if="
                      item.type === 'name' ||
                        item.type === 'stuId' ||
                        item.type === 'class' ||
                        item.type === 'school'
                    "
                    v-bind="ans.title"
                  >
                  </el-input>
                </div>

                <!-- <div v-if="item.type!=='name' && item.type!=='stuId' && item.type!=='class' && item.type!=='school'">
                    <div class="block-point" v-if="scoringMode !== false && item.point!==null && item.point!==undefined">
                      <div v-if="item.point===''">分值：0</div>
                      <div v-else>分值：{{ item.point }}</div>
                    </div>
  
                    <div class="block-refer" v-if="item.refer!==null && item.refer!==undefined">
                      <div v-if="item.refer===''">答案：无</div>
                      <div v-else>参考答案：{{ item.refer }}</div>
                    </div>
                  </div> -->

                <!--                 关联信息-->
                <div
                  class="block-relation"
                  v-if="isLogic && item.last_question !== 0"
                >
                  <div>{{ relatedQs(item) }}</div>
                </div>
              </el-col>

              <el-col
                :span="8"
                class="block-button"
                style="text-align: right"
                v-if="hoverItem === item.id"
              >
                <el-button-group>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Edit"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-edit"
                      @click="edit(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    v-if="isLogic"
                    effect="light"
                    content="Add relation"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-paperclip"
                      @click="logic(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Copy"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-document-copy"
                      @click="copy(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Delete"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-delete"
                      @click="del_pre(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Move up"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-caret-top"
                      @click="up(item.id)"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="light"
                    content="Move down"
                    placement="bottom"
                  >
                    <el-button
                      class="bt"
                      type="primary"
                      icon="el-icon-caret-bottom"
                      @click="down(item.id)"
                    ></el-button>
                  </el-tooltip>
                </el-button-group>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-main>
    </el-container>

    <el-dialog
      :title="qsEditDialogTitle"
      :visible.sync="qsEditDialogVisible"
      :before-close="cancel_pre"
      class="dialog"
    >
      <el-form ref="form" :model="willAddQuestion" label-width="100px">
        <el-form-item label="Question Type">
          <el-select
            :disabled="selectDisAble"
            v-model="willAddQuestion.type"
            placeholder="Select Question Type"
            @change="typeChange"
          >
            <el-option
              v-for="item in allType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <div
          class="is-info"
          v-if="
            willAddQuestion.type === 'template' ||
              willAddQuestion.type === 'stuId' ||
              willAddQuestion.type === 'class' ||
              willAddQuestion.type === 'school'
          "
        >
          <p style="padding-left: 5%">
            Please input the export template in such format: [4,2,3,1] <br />
            with the digits indicating the indexes of questions <br />
            and also the sequence of the exported questions
            <br />
            <br />
            Please add ONLY ONE export template for each questionnaire
          </p>
          <el-form-item
            label="template"
            style="width: 90%;"
            v-if="willAddQuestion.type === 'template'"
          >
            <el-input
              v-model="willAddQuestion.question_en"
              placeholder="[4,2,3,1]"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="题目标题"
            style="width: 100%;"
            v-if="willAddQuestion.type === 'stuId'"
          >
            <el-input value="学号" disabled></el-input>
          </el-form-item>
          <el-form-item
            label="题目标题"
            style="width: 100%;"
            v-if="willAddQuestion.type === 'class'"
          >
            <el-input value="班级" disabled></el-input>
          </el-form-item>
          <el-form-item
            label="题目标题"
            style="width: 100%;"
            v-if="willAddQuestion.type === 'school'"
          >
            <el-input value="学校" disabled></el-input>
          </el-form-item>
          <!-- <el-form-item label="是否必填" >
              <el-checkbox v-model="willAddQuestion.must">必填</el-checkbox>
            </el-form-item> -->
        </div>

        <div v-else class="not-info">
          <el-form-item label="Name" style="width: 90%;">
            <el-input
              v-model="willAddQuestion.name"
              placeholder="Name(tag)"
            ></el-input>
          </el-form-item>
          <el-form-item label="Question_en" style="width: 90%;">
            <el-input
              v-model="willAddQuestion.question_en"
              placeholder="question_en"
            ></el-input>
          </el-form-item>

          <el-form-item label="Question_cn" style="width: 90%;">
            <el-input
              v-model="willAddQuestion.question_cn"
              placeholder="question_cn"
            ></el-input>
          </el-form-item>

          <el-form-item label="Description_en" style="width: 90%;">
            <el-input
              v-model="willAddQuestion.description_cn"
              placeholder="description_en"
            ></el-input>
          </el-form-item>

          <el-form-item label="Description_cn" style="width: 90%;">
            <el-input
              v-model="willAddQuestion.description_cn"
              placeholder="description_cn"
            ></el-input>
          </el-form-item>

          <el-form-item label="Export_type">
            <el-select
              v-model="willAddQuestion.export_type"
              placeholder="choose export_type"
              @change="export_typeChange"
            >
              <el-option
                v-for="item in allExportType"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="Is Required">
            <el-checkbox v-model="willAddQuestion.must">Required</el-checkbox>
          </el-form-item>

          <template
            v-if="
              willAddQuestion.type === 'single_choice' ||
                willAddQuestion.type === 'multiple_choice' ||
                willAddQuestion.type === 'multiple_object'
            "
          >
            <el-form-item
              :label="'choices' + (index + 1)"
              v-for="(item, index) in willAddQuestion.options"
              :key="index"
            >
              <el-row>
                <el-col :span="16">
                  <el-input
                    v-model="item.title"
                    placeholder="Option Name"
                  ></el-input>
                </el-col>
                <el-col :span="8">
                  <el-button
                    type="danger"
                    plain
                    class="deleteOptionButton"
                    @click="deleteOption(index)"
                    >Delete Option</el-button
                  >
                </el-col>
              </el-row>
            </el-form-item>
            <el-button
              type="primary"
              plain
              class="addOptionButton"
              @click="addOption"
              >New Option</el-button
            >
          </template>

          <template v-if="willAddQuestion.type === 'string'">
            <!-- <el-form-item label="填空预览">
                <el-input
                    type="textarea"
                    :rows="willAddQuestion.row"
                    resize="none">
                </el-input>
              </el-form-item> -->
            <!-- <el-form-item label="行数">
                <el-input-number v-model="willAddQuestion.row" :min="1" :max="10" ></el-input-number>
              </el-form-item> -->
          </template>

          <!-- <template v-if="willAddQuestion.type==='mark'">
              <el-form-item label="最大分数">
                <el-input-number v-model="willAddQuestion.score" :min="1" :max="10" ></el-input-number>
              </el-form-item>
            </template> -->

          <!-- <template v-if="willAddQuestion.type==='judge'"></template> -->

          <!--          填空答案设置-->
          <!-- <el-form-item v-if="willAddQuestion.type==='text'" label="参考答案" style="width: 100%;">
              <el-input v-model="willAddQuestion.refer" placeholder="请输入参考答案"></el-input>
            </el-form-item> -->

          <!--          多选答案设置-->
          <!-- <el-form-item v-if="willAddQuestion.type==='checkbox'" label="参考答案" style="width: 100%;">
              <el-select v-model="willAddQuestion.refer" multiple placeholder="请选择">
                <el-option
                    v-for="item in willAddQuestion.options"
                    :key="item.id"
                    :value="item.title">
                </el-option>
              </el-select>
            </el-form-item> -->

          <!--          单选答案设置-->
          <!-- <el-form-item v-if="willAddQuestion.type==='radio'" label="参考答案" style="width: 100%;">
              <el-select v-model="willAddQuestion.refer" @change="radioChange">
                <el-option
                    v-for="item in willAddQuestion.options"
                    :key="item.id"
                    :value="item.title">
                </el-option>
              </el-select>
            </el-form-item> -->

          <!--          判断答案设置-->
          <!-- <el-form-item v-if="willAddQuestion.type==='judge'" label="参考答案" style="width: 100%;">
              <el-select v-model="willAddQuestion.refer" @change="judgeChange">
                <el-option
                    v-for="item in [{title: '对', id: '1'}, {title: '错', id: '2'}]"
                    :key="item.id"
                    :value="item.title">
                </el-option>
              </el-select>
            </el-form-item>
  
            <el-form-item v-if="scoringMode" label="分值" style="width: 100%;">
              <el-input-number v-model="willAddQuestion.point" :min="0" :max="1000"></el-input-number>
            </el-form-item> -->

          <!-- <el-form-item label="上传图片">
              <el-upload
                  class="upload-img"
                  :action=uploadImgUrl
                  multiple
                  :http-request="upLoadImage"
                  :before-upload="beforeImageUpload"
                  :file-list="willAddQuestion.imgList"
                  :limit="6">
                <el-button size="small"  plain style="width: 100px">点击上传</el-button>
                <span slot="tip" class="el-upload__tip">&emsp;只能上传jpg/png文件，且不超过5mb</span>
              </el-upload>
            </el-form-item>
  
            <el-form-item label="上传视频">
              <el-upload
                  class="upload-video"
                  :action=uploadVideoUrl
                  :http-request="upLoadVideo"
                  :before-upload="beforeVideoUpload"
                  :file-list="willAddQuestion.videoList"
                  :limit="1">
                <el-button size="small" plain style="width: 100px">点击上传</el-button>
                <span slot="tip" class="el-upload__tip">&emsp;只能上传mp4/mkv文件，且不超过10mb</span>
              </el-upload>
            </el-form-item> -->
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-row>
          <el-button :span="6" type="primary" @click="dialogConfirm"
            >Confirm</el-button
          >
          &emsp;
          <el-button :span="6" @click="cancel_pre">Cancel</el-button>
        </el-row>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="qsLinkDialogVisible"
      :title="qsLinkDialogTitle"
      class="linkDialog"
      :show-close="false"
      width="800px"
    >
      <el-row>
        <el-col span="8" style="text-align: center">
          <el-row>
            <div
              id="qrcode_2"
              style="height:200px; width:200px; margin-left: 22px;"
            ></div>
          </el-row>
        </el-col>
        <el-col span="16">
          <el-row><h2>链接与二维码</h2></el-row>
          <el-row style="margin-top:15px">
            <el-col :span="16" style="margin-right: 5px">
              <el-input
                :placeholder="linkShare"
                v-model="linkShare"
                id="copyText"
                :disabled="true"
              >
              </el-input>
            </el-col>
            <el-button type="info" plain id="copyBtn" @click="copyToClip"
              >复制链接</el-button
            ></el-row
          >
          <el-row style="margin-top: 25px">
            <el-button type="primary" plain @click="download"
              >下载二维码</el-button
            >
            <el-button
              type="primary"
              @click="genCodeAgain"
              style="margin-left: 30px"
              >重新生成链接</el-button
            >
          </el-row>
        </el-col>
      </el-row>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-row>
          <el-button
            :span="6"
            type="success"
            style="width: 80px"
            @click="finish"
            >完 成</el-button
          >
        </el-row>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="editWrongMsgVisible"
      class="linkDialog"
      :show-close="false"
      :close-on-click-modal="false"
      width="300px"
    >
      <span>{{ editWrongMsg }}</span>
      <span slot="footer" class="dialog-footer" style="text-align: center">
        <el-row>
          <el-button
            :span="6"
            type="danger"
            style="width: 80px"
            @click="editWrongMsgVisible = false"
            >知道了</el-button
          >
        </el-row>
      </span>
    </el-dialog>
    <!--    关联问题弹框-->
    <el-dialog
      :title="qsLogicDialogTitle"
      :visible.sync="qsLogicDialogVisible"
      :before-close="cancel_pre_logic"
      class="dialog"
    >
      <el-form
        v-if="questions.length !== 0"
        class="logic-form"
        ref="form"
        label-width="80px"
      >
        <div class="logic-title">
          Current Question：{{ questions[logicIndex].id }}.
          {{ questions[logicIndex].title }}
        </div>

        <el-form-item label="Related__Question">
          <el-select
            v-model="willAddLogic.question_id"
            placeholder="Please select related question"
          >
            <el-option
              v-for="item in questionsFilter"
              :key="item.id"
              :label="idTitle(item)"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          label="Related__Option"
          v-if="willAddLogic.question_id !== 0"
        >
          <el-select
            v-model="willAddLogic.option_id"
            placeholder="Please select related option"
          >
            <el-option
              v-for="item in questions[willAddLogic.question_id - 1].options"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <div class="logic-info" v-if="willAddLogic.question_id !== 0">
          <!--          保存加入后选项id记得减一-->
          Only when question<span style="color: #1687fd">
            "{{ idTitle(questions[willAddLogic.question_id - 1]) }}" </span
          >'s answer is<span style="color: #1687fd">
            "{{
              questions[willAddLogic.question_id - 1].options[
                willAddLogic.option_id - 1
              ].title
            }}" </span
          >, current question will be asked
        </div>
        <div class="logic-info" v-if="questionsFilter.length === 1">
          There is no single or multiple choice before this question, cannot set
          related question.
        </div>

        <div class="logic-bt">
          <el-button type="primary" @click="logicConfirm(logicIndex)"
            >Confirm</el-button
          >
        </div>
      </el-form>
    </el-dialog>
    <!--    高级设置弹框-->
    <el-dialog
      :title="settingDialogTitle"
      :visible.sync="settingDialogVisible"
      class="settingDialog"
      width="30%"
    >
      <el-form class="setting-form" ref="form" label-width="130px">
        <el-form-item label="问卷回收截止时间">
          <el-date-picker
            v-model="timeFrame"
            @change="formatTime"
            type="datetime"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="选择结束时间"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="题目关联逻辑开关">
          <el-switch
            v-model="isLogic"
            active-text="开"
            inactive-text="关"
            @change="closeGlobalLogic"
          >
          </el-switch>
        </el-form-item>

        <div class="logic-description">
          题目关联打开时，可设置一个题目的显示同前面某个题目的某个选项被选中关联在一起【问题右侧按钮处设置】
        </div>

        <div class="logic-description important" v-if="isLogic">
          为确保您的问卷关联逻辑正确，建议您最后为问卷设计关联逻辑
        </div>
      </el-form>

      <div class="setting-bt">
        <el-button type="primary" @click="settingSuccess">完成</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import editHeader from "../../../components/header/editHeader";
import QRCode from "qrcodejs2";
import getDataApi from "@/utils/getDataApi";
import saveDataApi from "@/utils/saveDataApi";
import user from "@/store/user";

export default {
  name: "test",
  mixins: [getDataApi, saveDataApi],
  data() {
    return {
      logicQuestionState: 0, // 用于判断用户是否更改关联问题设置
      logicOptionState: 0, // 用于判断用户是否更改关联选项设置

      uploadImgUrl: this.GLOBAL.backUrl + "upload/image",
      uploadVideoUrl: this.GLOBAL.backUrl + "upload/video",

      max_recycling: 10000,
      hasRecycleLimit: false,
      timer: "",
      value: "",
      qrcode: null,
      timeFrame: "",
      linkShare: "",
      editWrongMsg: "",
      editWrongMsgVisible: false,
      qsLinkDialogVisible: false,
      qsLinkDialogTitle: "发布成功！",
      settingDialogTitle: "高级设置", // 高级设置弹框的标题
      settingDialogVisible: false, // 高级设置对话框可见性
      closingDate: null, // 高级设置中问卷回收的截止日期
      isLogic: true, // 问卷是否引入关联逻辑
      scoringMode: true, // 评分模式（高级设置中开启后可为题目设置得分）
      isReleased: false, // 是否发布
      editIndex: 0,
      logicIndex: 0, // 当前添加题目逻辑的问题ID
      selectDisAble: false,
      hoverItem: 0,
      activeName: "first",
      title: "Please add questionnaire name",
      description: "",
      industry: "Please add questionnaire industry ID",
      company: "Please add questionnaire company type ID",
      defaultProps: {
        children: "children",
        label: "label",
      },
      type: "1",
      questions: [],
      outline: [],
      qid: this.$route.query.qid,
      qsEditDialogVisible: false,
      qsEditDialogTitle: "新建题目",
      qsLogicDialogVisible: false,
      qsLogicDialogTitle: "Relation Settings",
      willAddQuestion: {
        question_id: 0,
        id: 0,
        type: "",
        export_type: "string",
        question_en: "",
        question_cn: "",
        must: false, // 是否必填
        is_shown: true, // 是否显示
        last_question: 0, // 前导问题
        last_option: 0, // 前导选项
        description_en: "",
        description_cn: "",
        options: [
          {
            title: "", // 选项标题
            id: 1, // 选项id
          },
        ],
        row: 1, // 填空区域行数
        score: 10, // 最大评分
        refer: "", // 参考答案
        point: 0, // 分值
        // imgList: [],
        // videoList: [],
      },
      willAddLogic: {
        question_id: 0,
        option_id: 1,
      },
      allIndustry: "",

      allType: [
        {
          value: "single_choice",
          label: "single_choice",
        },
        {
          value: "multiple_choice",
          label: "multiple_choice",
        },
        {
          value: "string",
          label: "string",
        },
        {
          value: "float",
          label: "float",
        },
        {
          value: "email",
          label: "email",
        },
        {
          value: "date",
          label: "date",
        },
        {
          value: "multiple_object",
          label: "multiple_object",
        },
        {
          value: "address",
          label: "address",
        },
      ],
      allExportType: [
        {
          value: "string",
          label: "string",
        },
        {
          value: "index_0",
          label: "index_0",
        },
        {
          value: "index_1",
          label: "index_1",
        },
        {
          value: "yes/no",
          label: "yes/no",
        },
        {
          value: "true/false",
          label: "true/false",
        },
        {
          value: "1/0",
          label: "1/0",
        },
        //   {
        //     value:'',
        //     label:'',
        //   },
        //   {
        //     value:'',
        //     label:'',
        //   },
      ],

      // xiaoxueqi_questions: [
      //   {
      //     id: 1,
      //     type: 'single_choice',
      //     title: '这是一个什么网站？',
      //     description: '请考虑妥当后填写',
      //     must: true,
      //     options: [{
      //       id: 1,
      //       title: '问卷系统',
      //     }, {
      //       id: 2,
      //       title: '出版系统',
      //     }],
      //     row: 0,
      //     score: 0,
      //   },
      //   {
      //     id: 2,
      //     type: 'multiple_choice',
      //     title: '软工小学期助教都有谁？',
      //     description: '请考虑妥当后填写',
      //     must: false,
      //     options: [{
      //       id: 1,
      //       title: 'ZYH',
      //     }, {
      //       id: 2,
      //       title: 'LKW',
      //     },{
      //       id: 3,
      //       title: 'ZXH',
      //     }, {
      //       id: 4,
      //       title: 'HZH',
      //     }],
      //     row: 0,
      //     score: 0,
      //   },
      //   {
      //     id: 3,
      //     type: 'single_choice',
      //     title: '软工小学期累不累',
      //     description: '请考虑妥当后填写',
      //     must: false,
      //     options: [{
      //       id: 1,
      //       title: '累',
      //     }, {
      //       id: 2,
      //       title: '非常累',
      //     }],
      //     row: 0,
      //     score: 0,
      //   },
      //   {
      //     id: 4,
      //     type: 'string',
      //     title: '您对小学期的评价如何？',
      //     description: '请考虑妥当后填写',
      //     must: false,
      //     options: [{
      //       id: 0,
      //       title: '',
      //     }],
      //     row: 3,
      //     score: 0,
      //   },
      //   {
      //     id: 5,
      //     type: 'mark',
      //     title: '给小学期打个分吧~',
      //     description: '请考虑妥当后填写',
      //     must: true,
      //     options: [{
      //       id: 0,
      //       title: '',
      //     }],
      //     row: 0,
      //     score: 10,
      //   },
      // ],
      // xiaoxueqi_outline: [
      //   {
      //     id: 1,
      //     label: '1. 这是一个什么网站？',
      //   },
      //   {
      //     id: 2,
      //     label: '2. 软工小学期助教都有谁？',
      //   },
      //   {
      //     id: 3,
      //     label: '3. 软工小学期累不累',
      //   },
      //   {
      //     id: 4,
      //     label: '4. 您对小学期的评价如何？',
      //   },
      //   {
      //     id: 5,
      //     label: '5. 给小学期打个分吧~',
      //   },
      // ],
    };
  },
  components: {
    editHeader,
  },
  methods: {
    //---------------------------Image and video--------------------------------//
    upLoadImage(file) {
      const formData = new FormData();
      formData.append("image", file.file);
      this.$axios({
        method: "post",
        url: this.uploadImgUrl,
        data: formData,
      })
        .then((res) => {
          switch (res.data.status_code) {
            case 1:
              var name = res.data.name;
              var url = res.data.url;
              this.willAddQuestion.imgList.push({
                name: name,
                url: url,
              });
              console.log(this.willAddQuestion.imgList);
              break;
            case 2:
              // this.$message.error("上传文件格式错误！");
              break;
            default:
              this.$message.error("操作失败！");
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    upLoadVideo(file) {
      const formData = new FormData();
      formData.append("video", file.file);
      this.$ajax({});
      this.$axios({
        method: "post",
        url: this.uploadVideoUrl,
        data: formData,
      })
        .then((res) => {
          switch (res.data.status_code) {
            case 1:
              var name = res.data.name;
              var url = res.data.url;
              this.willAddQuestion.videoList.push({
                name: name,
                url: url,
              });
              console.log(this.willAddQuestion.videoList);
              break;
            case 2:
              // this.$message.error("上传文件格式错误！");
              break;
            default:
              this.$message.error("操作失败！");
              break;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    beforeImageUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt5M = file.size / 1024 / 1024 < 10;

      if (!isJPG && !isPNG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      } else if (!isLt5M) {
        this.$message.error("上传头像图片大小不能超过 5MB!");
      }
      return (isJPG || isPNG) && isLt5M;
    },
    beforeVideoUpload(file) {
      const filename = file.name;
      var suffix = "";
      var isVideo = false;
      const isLt2M = file.size / 1024 / 1024 < 30;
      try {
        var flieArr = filename.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      var videoList = ["mp4", "mkv"];
      var judge = videoList.some(function(item) {
        return item === suffix;
      });
      if (judge) {
        isVideo = true;
      }
      if (!isVideo) {
        this.$message.error("上传视频文件只能是 MP4/MKV 格式!");
      } else if (!isLt2M) {
        this.$message.error("上传视频文件大小不能超过 10MB!");
      }
      return isLt2M && isVideo;
    },
    beforeRemove(file, fileList) {
      console.log(file, fileList);
      return this.$confirm(`Confirm移除 ${file.name}？`);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.$message("移除成功");
    },
    //--------------------------------------------------------------------------//
    // autoSave() {
    //   this.saveQnInfo('autosave', '2');
    // },
    download() {
      // 获取base64的图片节点
      var img = document
        .getElementById("qrcode_2")
        .getElementsByTagName("img")[0];
      // 构建画布
      var canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      canvas.getContext("2d").drawImage(img, 0, 0);
      // 构造url
      var url = canvas.toDataURL("image/png");
      console.log(url);
      // 构造a标签并模拟点击
      var downloadLink = document.createElement("a");
      downloadLink.download = "二维码.png";
      downloadLink.href = url;
      downloadLink.click();
      downloadLink.remove();
    },
    formatTime(time) {
      this.timeFrame = time;
    },
    finish() {
      this.qsLinkDialogVisible = false;
      this.$router.push("/index"); // 跳转到问卷中心？
    },
    publishSuccess: function() {
      this.qsLinkDialogVisible = true;
    },
    edit: function(index) {
      index--;
      this.willAddQuestion = {
        question_id: this.questions[index].question_id,
        id: this.questions[index].id,
        name: this.questions[index].name,
        type: this.questions[index].type,
        question_en: this.questions[index].question_en,
        question_cn: this.questions[index].question_cn,
        must: this.questions[index].must,
        description_en: this.questions[index].description_en,
        description_cn: this.questions[index].description_cn,
        options: JSON.parse(JSON.stringify(this.questions[index].options)),
        row: this.questions[index].row,
        score: this.questions[index].score,
        refer: this.questions[index].refer,
        point: this.questions[index].point,
        is_shown: this.questions[index].is_shown,
        last_question: this.questions[index].last_question,
        last_option: this.questions[index].last_option,
        // imgList: JSON.parse(JSON.stringify(this.questions[index].imgList)),
        // videoList: JSON.parse(JSON.stringify(this.questions[index].videoList)),
      };
      this.editIndex = index;
      this.selectDisAble = true;
      this.qsEditDialogTitle = "编辑题目";
      this.qsEditDialogVisible = true;
    },
    isExistEmptyOption: function() {
      for (let i = 0; i < this.willAddQuestion.options.length; i++) {
        if (this.willAddQuestion.options[i].title === "") return true;
      }
      return false;
    },
    isExistSameOption: function() {
      for (let i = 0; i < this.willAddQuestion.options.length; i++) {
        for (let j = 0; j < this.willAddQuestion.options.length; j++) {
          if (
            i !== j &&
            this.willAddQuestion.options[i].title ===
              this.willAddQuestion.options[j].title
          )
            return true;
        }
      }
      return false;
    },
    dialogConfirm() {
      let index = this.editIndex;
      // this.qsEditDialogVisible = false;
      if (this.qsEditDialogTitle === "编辑题目") {
        this.questions[index].id = this.willAddQuestion.id;
        this.questions[index].row = this.willAddQuestion.row;
        this.questions[index].name = this.willAddQuestion.name;
        this.questions[index].export_type = this.willAddQuestion.export_type;
        this.questions[index].must = this.willAddQuestion.must;
        this.questions[
          index
        ].description_en = this.willAddQuestion.description_en;
        this.questions[
          index
        ].description_cn = this.willAddQuestion.description_cn;
        this.questions[index].question_en = this.willAddQuestion.question_en;
        this.questions[index].question_cn = this.willAddQuestion.question_cn;
        this.questions[index].options = this.willAddQuestion.options;
        this.questions[index].score = this.willAddQuestion.score;
        this.qsEditDialogTitle = "";
        this.qsEditDialogVisible = false;
        this.questions[index].refer = this.willAddQuestion.refer;
        this.questions[index].point = this.willAddQuestion.point;
        // 大纲更新
        this.updateOutline(
          this.willAddQuestion.id,
          this.willAddQuestion.question_en
        );
        if (this.willAddQuestion.name === "") {
          this.$message({
            type: "error",
            message: "Name cannot be empty!",
          });
        }
        if (this.willAddQuestion.question_en === "") {
          this.$message({
            type: "error",
            message: "Question_en cannot be empty!",
          });
        } else if (
          (this.willAddQuestion.type === "single_choice" ||
            this.willAddQuestion.type === "multiple_choice" ||
            this.willAddQuestion.type === "multiple_object") &&
          this.isExistEmptyOption()
        ) {
          this.$message({
            type: "error",
            message: "Option cannot be empty!",
          });
        } else if (
          (this.willAddQuestion.type === "single_choice" ||
            this.willAddQuestion.type === "multiple_choice" ||
            this.willAddQuestion.type === "multiple_object") &&
          this.isExistSameOption()
        ) {
          this.$message({
            type: "error",
            message: "Duplicated Option Name!",
          });
        } else {
          this.questions[index].id = this.willAddQuestion.id;
          this.questions[index].row = this.willAddQuestion.row;
          this.questions[index].name = this.willAddQuestion.name;
          this.questions[index].export_type = this.willAddQuestion.export_type;
          this.questions[index].must = this.willAddQuestion.must;
          this.questions[
            index
          ].description_en = this.willAddQuestion.description_en;
          this.questions[
            index
          ].description_cn = this.willAddQuestion.description_cn;
          this.questions[index].question_en = this.willAddQuestion.question_en;
          this.questions[index].question_cn = this.willAddQuestion.question_cn;
          this.questions[index].options = this.willAddQuestion.options;
          this.questions[index].score = this.willAddQuestion.score;
          this.questions[index].is_shown = this.willAddQuestion.is_shown;
          this.questions[
            index
          ].last_question = this.willAddQuestion.last_question;
          this.questions[index].last_option = this.willAddQuestion.last_option;
          this.questions[index].options = JSON.parse(
            JSON.stringify(this.willAddQuestion.options)
          );
          // this.questions[index].imgList=JSON.parse(JSON.stringify(this.willAddQuestion.imgList));
          // this.questions[index].videoList=JSON.parse(JSON.stringify(this.willAddQuestion.videoList));
          // 大纲更新
          this.updateOutline(
            this.willAddQuestion.id,
            this.willAddQuestion.question_en
          );
          this.qsEditDialogTitle = "";
          this.qsEditDialogVisible = false;
          this.$message({
            type: "success",
            message: "Successful!",
          });
          // 重置
          this.resetWillAdd();
          this.selectDisAble = false;
        }
        // 重置
        this.willAddQuestion = {
          id: 0,
          type: "",
          question_en: "",
          question_cn: "",
          must: false,
          description_en: "",
          description_cn: "",
          options: [
            {
              title: "", //选项标题
              id: 1, //选项id
            },
          ],
          row: 1,
          score: 10,
          refer: "",
          point: 0, // 分值
        };
        this.selectDisAble = false;
      } else {
        // if (this.willAddQuestion.type==='name') { this.willAddQuestion.title = '姓名'; this.willAddQuestion.refer = ''; }
        // if (this.willAddQuestion.type==='stuId') { this.willAddQuestion.title = '学号'; this.willAddQuestion.refer = '';}
        // if (this.willAddQuestion.type==='class') { this.willAddQuestion.title = '班级'; this.willAddQuestion.refer = '';}
        // if (this.willAddQuestion.type==='school') { this.willAddQuestion.title = '学校'; this.willAddQuestion.refer = '';}
        if (this.willAddQuestion.name === "") {
          this.$message({
            type: "error",
            message: "Name cannot be empty!",
          });
        }
        if (this.willAddQuestion.question_en === "") {
          this.$message({
            type: "error",
            message: "Question_en cannot be empty!",
          });
        } else if (
          (this.willAddQuestion.type === "single_choice" ||
            this.willAddQuestion.type === "multiple_choice" ||
            this.willAddQuestion.type === "multiple_object") &&
          this.isExistEmptyOption()
        ) {
          this.$message({
            type: "error",
            message: "Option cannot be empty!",
          });
        } else if (
          (this.willAddQuestion.type === "single_choice" ||
            this.willAddQuestion.type === "multiple_choice" ||
            this.willAddQuestion.type === "multiple_object") &&
          this.isExistSameOption()
        ) {
          this.$message({
            type: "error",
            message: "Duplicated option!",
          });
        } else {
          this.qsEditDialogVisible = false;
          this.willAddQuestion.id = this.questions.length + 1;
          // 大纲更新
          this.updateOutline(
            this.willAddQuestion.id,
            this.willAddQuestion.question_en
          );
          // if (this.willAddQuestion.type==='judge') {
          //   this.willAddQuestion.options = [{title: '对', id: 1}, {title: '错', id: 2}];
          // }
          this.questions.push(this.willAddQuestion);
          this.$message({
            type: "success",
            message: "添加成功!",
          });
          // 重置
          this.resetWillAdd();
        }
      }
    },
    resetWillAdd() {
      this.willAddQuestion = {
        question_id: 0,
        id: 0,
        type: "",
        question_en: "",
        question_cn: "",
        must: false, // 是否必填
        is_shown: true, // 是否显示
        last_question: 0, // 前导问题
        last_option: 0, // 前导选项
        description_en: "",
        description_cn: "",
        options: [
          {
            title: "", // 选项标题
            id: 1, // 选项id
          },
        ],
        row: 1, // 填空区域行数
        score: 10, // 最大评分
        refer: "", // 参考答案
        point: 0, // 分值
        // imgList:[],
        // videoList:[]
      };
    },
    dialogCancel: function() {
      this.qsEditDialogTitle = "New Question";
      this.resetWillAdd();
      this.qsEditDialogVisible = false;
      this.selectDisAble = false;
    },
    cancel_pre: function() {
      this.$confirm(
        "Info you have input will not be saved, are you sure you want to close this window?",
        "提示",
        {
          confirmButtonText: "Comfirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.dialogCancel();
        })
        .catch(() => {});
    },
    LogicDialogCancel: function() {
      this.qsLogicDialogVisible = false;
      this.resetLogic();
    },
    cancel_pre_logic: function() {
      this.$confirm(
        "Info you have input will not be saved, are you sure you want to close this window?",
        "提示",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.LogicDialogCancel();
        })
        .catch(() => {});
    },
    logicConfirm: function(index) {
      console.log(this.questions);
      let questions = this.questions;
      let willAddLogic = this.willAddLogic;
      questions[index].last_question = willAddLogic.question_id;
      questions[index].last_option = willAddLogic.option_id;
      questions[index].is_shown = willAddLogic.question_id === 0;
      if (
        this.willAddLogic.question_id !== this.logicQuestionState ||
        this.willAddLogic.option_id !== this.logicOptionState
      ) {
        this.$message({
          type: "success",
          message: "Successfully set relation.",
        });
      }
      this.qsLogicDialogVisible = false;
      this.resetLogic();
    },
    logic: function(index) {
      this.logicIndex = index - 1;
      this.willAddLogic.question_id = this.questions[index - 1].last_question;
      if (this.questions[index - 1].last_option !== 0) {
        this.willAddLogic.option_id = this.questions[index - 1].last_option;
      } else this.willAddLogic.option_id = 1;
      console.log(this.questions);
      this.qsLogicDialogVisible = true;
      this.logicQuestionState = this.willAddLogic.question_id;
      this.logicOptionState = this.willAddLogic.option_id;
    },
    resetLogic() {
      this.willAddLogic = {
        question_id: 0,
        option_id: 1,
      };
      this.logicIndex = 0;
    },
    typeChange(value) {
      this.willAddQuestion.type = value;
    },
    export_typeChange(value) {
      this.willAddQuestion.export_type = value;
    },
    radioChange(value) {
      this.willAddQuestion.refer = value;
    },
    judgeChange(value) {
      this.willAddQuestion.refer = value;
    },
    addOption() {
      // 问题关联检查
      // if (this.willAddQuestion.id !== 0 && this.sonGroup(this.willAddQuestion.id-1).length !== 0) {
      //   this.$message({
      //     type: 'warning',
      //     message: '此问题已被关联，无法新增选项，请删除关联信息后再试'
      //   });
      //   return;
      // }
      this.willAddQuestion.options.push({
        title: "",
        id: this.willAddQuestion.options.length + 1,
      });
    },
    deleteOption(index) {
      if (this.willAddQuestion.options.length === 1) {
        this.$message({
          type: "error",
          message: "At least one option!",
        });
      } else {
        // 问题关联检查
        if (
          this.willAddQuestion.id !== 0 &&
          this.sonGroup(this.willAddQuestion.id - 1).length !== 0
        ) {
          this.$message({
            type: "warning",
            message:
              "There is a question related to this question and options in this question cannot be deleted, please cancel the relation and then try deleting option again.",
          });
          return;
        }
        // id重排
        for (let i = index + 1; i < this.willAddQuestion.options.length; i++) {
          this.willAddQuestion.options[i].id--;
        }
        this.willAddQuestion.options.splice(index, 1);
      }
    },
    changeTitle: function(value) {
      this.title = value;
    },
    changeDescription: function(value) {
      this.description = value;
    },
    changeIndustry: function(value) {
      this.industry = value;
    },
    changeCompany: function(value) {
      this.company = value;
    },
    openSetting: function() {
      this.settingDialogVisible = true;
    },
    settingSuccess: function() {
      this.settingDialogVisible = false;
      this.$message({
        type: "success",
        message: "Successful",
      });
    },
    genCodeAgain() {
      const formData = new FormData();
      formData.append("qn_id", this.$route.query.qid);
      this.$axios({
        method: "post",
        url: "/qn/change/code",
        data: formData,
      })
        .then((res) => {
          if (res.data.status_code === 1) {
            this.linkShare =
              this.GLOBAL.baseUrl + "/fill_test?mode=1&code=" + res.data.code;

            if (this.qrcode == null) {
              this.qrcode = new QRCode(document.getElementById("qrcode_2"), {
                width: 200, //生成的二维码的宽度
                height: 200, //生成的二维码的高度
                colorDark: "#000000", // 生成的二维码的深色部分
                colorLight: "#ffffff", //生成二维码的浅色部分
                correctLevel: QRCode.CorrectLevel.H,
              });
            }
            this.qrcode.clear();
            this.qrcode.makeCode(this.linkShare);
          } else {
            this.$message.error("请求失败！");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    share() {
      // this.publish();
    },
    publish() {
      console.log(user);
      // if (this.questions.length === 0) {
      //   this.$message.error("题目为空，无法发布！");
      //   return;
      // }

      // this.$confirm('确认发布？', '提示', {
      //   confirmButtonText: 'Confirm',
      //   cancelButtonText: 'Cancel',
      //   type: 'success'
      // }).then(() => {
      //   let loadingIns = this.$loading({fullscreen: true, text: '拼命加载中'});
      //   this.publishSuccess();
      //   var new_questions = JSON.parse(JSON.stringify(this.questions));
      //   let url = '/sm/save/qn/deploy';
      //   for (var i=0; i<new_questions.length; i++) {
      //     if (new_questions[i].type === 'multiple_choice') {
      //       new_questions[i].refer = new_questions[i].refer.join('-<^-^>-');
      //     }
      //   }
      //   const userInfo = user.getters.getUser(user.state());
      //   var param = {
      //     username: userInfo.user.username,
      //     title: this.title,
      //     finished_time: this.timeFrame,
      //     description: this.description,
      //     type: this.type,
      //     qn_id: this.$route.query.qid,
      //     questions: new_questions
      //   };
      //   var paramer = JSON.stringify(param, {questions: 'brackets'})
      //   this.$axios({
      //     method: 'post',
      //     url: url,
      //     data: paramer,
      //   })
      //       .then(res => {
      //         loadingIns.close();
      //         if (res.data.status_code === 1) {
      //           this.linkShare = this.GLOBAL.baseUrl + '/fill_test?mode=1&code=' + res.data.code;

      //           if (this.qrcode == null) {
      //             this.qrcode = new QRCode(document.getElementById("qrcode_2"), {
      //               width: 200, //生成的二维码的宽度
      //               height: 200, //生成的二维码的高度
      //               colorDark : "#000000", // 生成的二维码的深色部分
      //               colorLight : "#ffffff", //生成二维码的浅色部分
      //               correctLevel : QRCode.CorrectLevel.H
      //             });
      //           }
      //           this.qrcode.clear();
      //           this.qrcode.makeCode(this.linkShare);
      //         }
      //         else if (res.data.status_code === 2) {
      //           this.$message.error("题目为空，无法发布！");
      //         }
      //       })
      //       .catch(err => {
      //         console.log(err);
      //       })
      // }).catch(() => {
      //   this.$message({
      //     type: 'info',
      //     message: 'Cancel发布'
      //   });
      // });
    },
    saveinfo(tag) {
      this.saveQnInfo(tag, "2");
    },
    save() {
      if (this.title === "Please add questionnaire name") {
        this.$message.error("Please add a questionnaire name");
        return;
      }
    },
    preview() {
      // this.saveinfo('preview');
    },
    up: function(index) {
      index--;
      let questions = this.questions;
      if (index !== 0) {
        // 非法移动
        if (questions[index].last_question === index) {
          this.$message.error(
            "Cannot move relate question (child) above related question (parent)!"
          );
          return;
        }
        // 关联维护
        let arr = this.sonGroup(index);
        let willSubtract = [];
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            willSubtract.push(arr[j].id - 1);
          }
        }
        arr = this.sonGroup(index - 1);
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            questions[arr[j].id - 1].last_question++;
          }
        }
        for (let i = 0; i < willSubtract.length; i++) {
          questions[willSubtract[i]].last_question--;
        }
        // 题序维护
        questions[index].id--;
        questions[index - 1].id++;
        let temp = questions[index];
        questions[index] = questions[index - 1];
        questions[index - 1] = temp;
        // 大纲维护
        this.updateOutline(index, questions[index - 1].question_en);
        this.updateOutline(index + 1, questions[index].question_en);
      }
    },
    down: function(index) {
      index--;
      let questions = this.questions;
      if (index !== questions.length - 1) {
        // 非法移动
        if (questions[index + 1].last_question === index + 1) {
          this.$message.error(
            "Cannot move relate question (child) above related question (parent)!"
          );
          return;
        }
        // 关联维护
        let arr = this.sonGroup(index);
        let willAdd = [];
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            willAdd.push(arr[j].id - 1);
          }
        }
        arr = this.sonGroup(index + 1);
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            questions[arr[j].id - 1].last_question--;
          }
        }
        for (let i = 0; i < willAdd.length; i++) {
          questions[willAdd[i]].last_question++;
        }
        // 题序维护
        questions[index].id++;
        questions[index + 1].id--;
        let temp = questions[index];
        questions[index] = questions[index + 1];
        questions[index + 1] = temp;
        // 大纲维护
        this.updateOutline(index + 1, questions[index].question_en);
        this.updateOutline(index + 2, questions[index + 1].question_en);
      }
    },
    del_pre: function(index) {
      // 问题关联检查
      if (this.sonGroup(index - 1).length !== 0) {
        this.$confirm(
          "This question has been related, delete this question will also delete the relation.",
          "提示",
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.del_relation(index);
            this.del(index);
            this.$message({
              type: "success",
              message: "Question and relation has been deleted",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Cancelled",
            });
          });
      } else {
        this.$confirm(
          "Are you sure you want to cancel this? (Cannot recover)",
          "提示",
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.del(index);
            this.$message({
              type: "success",
              message: "Delete successfuly",
            });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Cancel",
            });
          });
      }
    },
    del: function(index) {
      index--;
      let questions = this.questions;
      let outline = this.outline;
      // 维护关联关系
      for (let i = index + 1; i < questions.length; i++) {
        let arr = this.sonGroup(i);
        if (arr.length !== 0) {
          for (let j = 0; j < arr.length; j++) {
            if (questions[arr[j].id - 1] !== index) {
              questions[arr[j].id - 1].last_question--;
            }
          }
        }
      }
      // 维护题序
      for (let i = index + 1; i < questions.length; i++) {
        questions[i].id--;
      }
      outline.splice(index, 1);
      questions.splice(index, 1);
      // 维护大纲
      for (let num = index; num < outline.length; num++) {
        this.updateOutline(num + 1, questions[num].question_en);
      }
    },
    copy: function(index) {
      index--;
      let questions = this.questions;
      // 大纲更新
      this.updateOutline(this.outline.length + 1, questions[index].question_en);
      // 问卷更新
      let temp = this.deepClone(questions[index]);
      temp.id = questions.length + 1;
      temp.question_id = 0;
      questions.push(temp);
      this.$message.success(
        "A new copy of this question has been attached to the end of the questionnaire."
      );
    },
    deepClone: function(initialObj) {
      let obj = {};
      try {
        obj = JSON.parse(JSON.stringify(initialObj));
        // eslint-disable-next-line no-empty
      } finally {
      }
      return obj;
    }, // 深拷贝
    updateOutline: function(id, label) {
      if (label.length > 12) {
        label = id + ". " + label.substring(0, 11) + "...";
      } else {
        label = id + ". " + label;
      }
      if (id <= this.questions.length) {
        this.outline[id - 1].id = id;
        this.outline[id - 1].label = label;
      } else {
        this.outline.push({
          id: id,
          label: label,
        });
      }
    },
    updateQuestions: function(start, end) {
      let offset = end - start;
      if (offset > 0) {
        for (let i = 0; i < offset; i++) {
          this.down(start + i);
        }
      } else {
        for (let i = 0; i < offset * -1; i++) {
          this.up(start - i);
        }
      }
    }, // good
    toFillQn: function(value) {
      this.$router.push({
        name: "FillQn",
        query: {
          mode: value,
          title: this.title,
        },
      });
    },
    InitOutline() {
      for (var i = 0; i < this.questions.length; i++) {
        this.outline.push({
          id: this.questions[i].id,
          label: i + 1 + ". " + this.questions[i].title,
        });
      }
    },
    copyToClip(message) {
      var aux = document.createElement("input");
      aux.setAttribute("value", this.linkShare);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      if (message !== null) {
        this.$message.success("复制成功");
      } else {
        this.$message.error("复制失败");
      }
    },
    // 大纲拖拽处理函数(下3个)
    handleDrop(draggingNode, dropNode, dropType) {
      console.log("tree drop: ", draggingNode.key, dropNode.key, dropType);
      if (this.isRightRelation(draggingNode.key, dropNode.key)) {
        this.updateQuestions(draggingNode.key, dropNode.key);
      } else {
        this.outline.splice(0, this.outline.length);
        this.InitOutline();
        this.$message.error(
          "Cannot move relate question (child) above related question (parent)!"
        );
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      if (draggingNode.key > dropNode.key) {
        return type === "prev";
      } else {
        return type === "next";
      }
    },
    isRightRelation: function(index1, index2) {
      console.log(index1, index2);
      index1--;
      index2--;
      let questions = this.questions;
      if (index1 < index2) {
        for (let i = index1 + 1; i <= index2; i++) {
          if (questions[i].last_question === index1 + 1) {
            return false;
          }
        }
        return true;
      } else {
        for (let i = index1 - 1; i >= index2; i--) {
          if (questions[index1].last_question === i + 1) {
            return false;
          }
        }
        return true;
      }
    },
    idTitle: function(item) {
      if (item.id === 0) {
        return item.question_en;
      } else {
        return item.id + ". " + item.question_en;
      }
    },
    relatedQs: function(item) {
      return (
        "Relate to question：" +
        this.questions[item.last_question - 1].id +
        "." +
        this.questions[item.last_question - 1].question_en +
        "(" +
        this.questions[item.last_question - 1].options[item.last_option - 1]
          .question_en +
        ")"
      );
    },
    // 遍历得爸爸
    fatherIndex: function(qs_id) {
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].question_id === qs_id) {
          return i;
        }
      }
    },
    // 遍历得儿子
    sonGroup: function(index) {
      let arr = [];
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].last_question === this.questions[index].id) {
          arr.push(this.questions[i]);
        }
      }
      return arr;
    },
    // 连带删除关联信息
    del_relation: function(index) {
      for (let i = 0; i < this.questions.length; i++) {
        // 保存加入后,下面需修改id为question_id
        if (this.questions[i].last_question === this.questions[index - 1].id) {
          this.questions[i].last_option = 0;
          this.questions[i].last_question = 0;
          this.questions[i].is_shown = true;
        }
      }
    },
    // 关联信息存在性判断
    isExistRelation: function() {
      for (let i = 0; i < this.questions.length; i++) {
        if (this.questions[i].last_question !== 0) {
          return true;
        }
      }
      return false;
    },
    // 监听：全局删除关联信息
    closeGlobalLogic: function(state) {
      if (!state && this.isExistRelation()) {
        this.$confirm(
          "Closing setting will delete all relation info.",
          "提示",
          {
            confirmButtonText: "Confirm",
            cancelButtonText: "Cancel",
            type: "warning",
          }
        )
          .then(() => {
            this.deleteGlobalRelation();
            this.$message.info("All deleted");
          })
          .catch(() => {
            this.isLogic = true;
            this.$message.info("Cancel");
          });
      }
    },
    // 删除全局关联信息
    deleteGlobalRelation: function() {
      for (let i = 0; i < this.questions.length; i++) {
        this.questions[i].last_question = 0;
        this.questions[i].last_option = 0;
        this.questions[i].is_shown = true;
      }
    },
  },
  computed: {
    questionsFilter: function() {
      let questions = this.questions;
      let arr = [
        {
          question_id: 0,
          id: 0,
          type: "",
          title: "No related question",
          must: false, // 是否必填
          is_shown: true, // 是否显示
          last_question: 0, // 前导问题
          last_option: 0, // 前导选项
          description: "", // 问题描述
          options: [
            {
              title: "", // 选项标题
              id: 0, // 选项id
            },
          ],
          row: 1, // 填空区域行数
          score: 5, // 最大评分
        },
      ];
      for (let i = 0; i < this.logicIndex; i++) {
        if (
          questions[i].type === "single_choice" ||
          questions[i].type === "multiple_choice" ||
          questions[i].type === "multiple_object"
        ) {
          arr.push(questions[i]);
        }
      }
      return arr;
    },
  },
  created() {
    // this.getQnDataSelf();
    if (this.qid != undefined) {
      this.$axios({
        method: "get",
        url:
          "https://api.oxvalue.ai/api/question/v0.1/questionnaire_admin/" +
          this.qid +
          "/",
        headers: {
          Authorization: window.localStorage.getItem("ova_admin_token"),
        },
      }).then((res) => {
        console.log(JSON.parse(res.data.data.questions));
        this.title = res.data.data.name;
        if (res.data.data.description != "")
          this.description = res.data.data.description;
        this.industry = res.data.data.fk_industry_id;
        this.company = res.data.data.fk_company_type_id;
        this.questions = [];
        this.outline = [];
        var questions = JSON.parse(res.data.data.questions);
        for (var type in questions) {
          for (var q in questions[type]) {
            var current_question = questions[type][q];
            // console.log(JSON.parse(current_question['choices']))
            var question = {
              question_id: parseInt(q),
              name: current_question["name"],
              id: parseInt(q),
              type: current_question["type"],
              export_type: current_question["export_type"],
              question_en: current_question["question_en"],
              question_cn: current_question["question_cn"],
              must: current_question["is_required"],
              is_shown: true,
              last_question: 0,
              last_option: 0,
              description_en: current_question["description_en"],
              description_cn: current_question["description_cn"],
              options: [],
              row: 1,
            };
            this.questions.push(question);
          }
        }
        console.log(this.questions);
        // questions: [
        //   {
        //     question_id: 1,
        //     id: 1,
        //     type: 'single_choice',
        //     export_type: 'string',
        //     title: '这是一个什么网站？',
        //     must: true, // 是否必填
        //     is_shown: true,   // 是否显示
        //     last_question: 0,   // 前导问题
        //     last_option: 0,     // 前导选项
        //     description: '', // 问题描述
        //     options:[
        //       {
        //         title: '12', // 选项标题
        //         id: 1 // 选项id
        //       }
        //     ],
        //     row: 1, // 填空区域行数
        //   },
        // ],
        // outline: [
        //   {
        //     id: 1,
        //     label: '1. 这是一个什么网站？',
        //   },
        // ],
      });
    }
  },
  mounted() {
    // this.timer = setInterval(this.autoSave, 60000);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style>
.test .linkDialog {
  text-align: left;
}
.test .container {
  padding: 0;
  height: auto;
  min-height: 610px;
}

.test header {
  padding: 0 100px;
}

.test .el-container {
  padding: 0 100px;
}

.test .side {
  border-top: solid 1px #e3e3e3;
  border-right: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .main {
  border-top: solid 1px #e3e3e3;
  background: #ffffff;
}

.test .edit {
  margin-top: 0;
  overflow: auto;
  height: 550px;
}

.test .outline {
  overflow: auto;
  height: 550px;
}

.test .ques-type {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .edit-title {
  color: black;
  padding: 15px 0;
  font-size: 16px;
  border-bottom: solid #e3e3e3 2px;
}

.test .edit-ques {
  padding: 15px 0;
  font-size: 16px;
  border-bottom: dashed #e3e3e3 1px;
}

.test .type-icon {
  color: #1687fd;
  display: inline-block;
}

.test .type-icon:hover {
  color: #409eff;
  cursor: pointer;
}

.test .el-tabs__nav-scroll {
  text-align: center;
  height: 60px;
  margin: 0 60px;
}

.test .el-tabs__item {
  font-weight: bold;
  padding: 0 20px;
  height: 60px;
  box-sizing: border-box;
  line-height: 60px;
  display: inline-block;
  list-style: none;
  font-size: 16px;
  color: black;
  position: relative;
}

.test .el-tabs__header {
  margin: 0;
}

.test .el-tree-node__content {
  padding-left: 10px !important;
  height: 40px;
}

.test .main {
  max-height: 610px;
}

.test .ques .title {
  font-size: 28px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 26px;
}

.test .ques .description {
  text-align: left;
  font-size: 16px;
  padding-bottom: 30px;
  color: black;
  line-height: 30px;
}

.test .ques-block {
  padding-bottom: 15px;
  border-top: solid #e3e3e3 1px;
}

.test .ques-block:hover {
  background: #f5f5f5;
  transition: 0.3s;
}

.test .ques-block .must {
  font-weight: normal;
  color: crimson;
}

.test .block-title {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 20px 10px 10px;
  font-weight: bold;
}

.test .block-description {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 10px;
  padding-left: 10px;
  color: #969696;
}

.test .block-refer {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #51c215;
}

.test .block-point {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #e59824;
}

.test .block-choice {
  text-align: left;
  /*border: solid 1px black;*/
  font-size: 16px;
  padding: 5px 10px 10px;
}

.test .el-button-group > .el-button:first-child {
  border-radius: 0 0 0 8px;
}

.test .el-button-group > .el-button:last-child {
  border-radius: 0 0 8px 0;
}

.test .block-button .el-button {
  background: #b9b9b9;
  border: transparent;
  padding: 12px 16px;
  font-size: 16px;
}

.test .block-button .el-button:hover {
  background: #989898;
  border: transparent;
}

.test .bt {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.test .block-choice .el-textarea__inner {
  max-height: 100px;
}

.test .dialog {
  text-align: left;
  font-size: large;
}
.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
}
.test .deleteOptionButton {
  margin-left: 20px;
}

.test .logic-description {
  margin-top: -15px;
  margin-bottom: 30px;
  text-align: left;
  padding-left: 6px;
  font-size: 14px;
  color: #aaaaaa;
}

.test .important {
  color: crimson;
}

.test .logic-title {
  font-size: 16px;
  font-weight: bold;
  padding-left: 12px;
  padding-bottom: 30px;
}

.test .logic-bt {
  text-align: center;
}

.test .addOptionButton {
  display: inline-block;
  margin-left: 100px;
  margin-bottom: 22px;
}

.test .deleteOptionButton {
  margin-left: 20px;
}

.test .settingDialog {
  text-align: center;
}

.test .settingDialog .el-dialog__body {
  padding-left: 30px;
}

.test .logic-form .el-input--suffix .el-input__inner {
  width: 620px;
}

.test .logic-info {
  color: #aaaaaa;
  padding-top: 2px;
  padding-left: 12px;
  padding-bottom: 25px;
}

.test .block-relation {
  text-align: right;
  /*border: solid 1px black;*/
  font-size: 14px;
  padding-top: 8px;
  padding-left: 10px;
  color: #1687fd;
}
</style>
